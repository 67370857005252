<template>
  <div>
    <el-card class="box-card" shadow="never" style="margin-top: 10px">
      <Search :is-type="isType" :isShow="false" @searchData="searchData"></Search>
      <br>
      <!--            <InfoDetail></InfoDetail>-->
      <el-tabs @tab-click="handleClick" v-model="activeName">
        <el-tab-pane :label="`可报名(${allcount.able_count})`" name="able"
                     v-if="allcount.able_count!==undefined"></el-tab-pane>
        <el-tab-pane :label="`我的报名(${allcount.mine_count})`" name="mine"
                     v-if="allcount.mine_count!==undefined"></el-tab-pane>
        <el-tab-pane :label="`已初选的(${allcount.employ_count})`" name="employ"
                     v-if="allcount.employ_count!==undefined"></el-tab-pane>
        <el-tab-pane :label="`已合作的(${allcount.confirm_count})`" name="confirm"
                     v-if="allcount.employ_count!==undefined"></el-tab-pane>
        <div class="default-table">
          <el-table :data="tableData" border
                    stripe
                    style="width: 100%">
            <el-table-column
                align="center"
                label="序号"
                type="index"
                width="60"
            >
            </el-table-column>
            <el-table-column
                align="center"
                label="创建日期"
                prop="created_at"
                show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
                align="center"
                label="项目名称"
                min-width="200"
                prop="name"
                show-overflow-tooltip
            >
              <template slot-scope="{row}">
                <el-link @click="detailPage(row.id)" size="mini"
                         type="primary" v-if="userPermissions.indexOf('inquiry_apply_detail') !== -1">
                  <span>{{ row.name }}</span>
                </el-link>
                <span v-else>{{ row.name }}</span>
              </template>
            </el-table-column>
            <el-table-column
                align="center"
                label="品牌"
                prop="brand"
                show-overflow-tooltip
                width="100"
            >
            </el-table-column>
            <el-table-column
                align="center"
                label="产品"
                prop="product"
                show-overflow-tooltip
                min-width="120"
            >
            </el-table-column>

            <el-table-column
                align="center"
                label="直播平台"
                prop="platforms"
                show-overflow-tooltip
            >
              <template slot-scope="{row}">
                {{ String(row.platforms) }}
              </template>
            </el-table-column>
            <el-table-column
                align='center'
                min-width="110"
                label='是否平台下单'
                prop='is_platform_pay'
                show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
                align='center'
                label='直播机制'
                prop='live_mec'
                show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
                align='center'
                label='是否保量'
                prop='is_quantity'
                show-overflow-tooltip
            >
              <template slot-scope='{row}'>
                {{ row.is_quantity === 1 ? '是' : '否' }}
              </template>
            </el-table-column>
            <el-table-column
                label="期望档期"
                align="center"
                prop="expect_start"
                show-overflow-tooltip
            >
              <template slot-scope="{row}">
                {{ row.expect_start.slice(0, 10) }}至{{ row.except_end.slice(0, 10) }}
              </template>
            </el-table-column>
            <el-table-column
                align="center"
                label="项目状态" prop="status"
                show-overflow-tooltip
                width="110"
            >
              <template slot-scope='{row}'>
                <el-tag
                    :type="row?.status == 1 ?'success':'danger'"
                    effect="plain">
                  {{
                    row?.status == 1 ? '进行中' : row?.status == 2 ? '已结束' : row?.status == 3 ? '已作废' : '已完结'
                  }}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column align="center" fixed="right" label="操作" width="120">
              <template slot-scope="{row}">
                <el-row>
                  <el-col :span="12">
                    <el-link @click="detailPage(row.id)" icon="el-icon-c-scale-to-original"
                             size="mini"
                             type="primary"
                             v-if="userPermissions.indexOf('inquiry_apply_detail') !== -1">
                      {{ activeName == 'able' ? '报名' : '查看' }}
                    </el-link>
                  </el-col>
                </el-row>
              </template>
            </el-table-column>
          </el-table>
          <el-row>
            <el-col :span="24" style="text-align: right">
              <Pagination :limit.sync="tablePagination.page_size"
                          :page.sync="tablePagination.current_page"
                          :total="tablePagination.total"
                          @pagination="infoData"/>
            </el-col>
          </el-row>
        </div>

      </el-tabs>
    </el-card>
  </div>
</template>

<script>
import Search from './components/search'
import InfoDetail from './InfoDetail'
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import { mapGetters } from 'vuex'

export default {
  name: 'index',
  components: { Search, InfoDetail, PageHeaderLayout },
  data() {
    return {
      tableData: [],
      drawerVisible: false,
      rowId: 'add',
      activeName: 'mine',
      tablePagination: {
        total: 0,
        page_size: 15,
        offset: 0,
        current_page: 1
      },
      allcount: {},
      searchDatas: {},
      isType: false
    }
  },
  computed: {
    ...mapGetters(['userPermissions', 'userInfo'])
  },
  watch: {
    drawerVisible: {
      handler(val) {
        if (!val) {
          this.infoData()
        }
      }
    }
  },
  methods: {
    handleClick() {
      this.infoData()

    },
    searchData(val) {
      this.searchDatas = val
      let cond = {}
      let name = ['name', 'status', 'brand', 'created_start', 'created_end', 'expect_start', 'expect_end', 'is_apply']
      name.forEach((type) => {
        if (val[type] != '') {
          cond[type] = val[type]
        }
      })
      if (val.intentions?.length != 0) {
        cond['intentions'] = val.intentions
      }
      if (val.platforms?.length != 0) {
        cond['platforms'] = val.platforms
      }
      this.searchDatas = cond
      this.infoData()
    },

    async infoData() {
      let searchCondition = this.searchDatas
      Object.assign(searchCondition, {
        page_size: this.tablePagination.page_size || 15,
        current_page: this.tablePagination.current_page || 1
      }, { case_type: this.activeName })
      searchCondition.type = this.isType ? 1 : 2
      let { list, page_info } = await this.$api.listApplyInquiry(searchCondition)

      // applyCount
      this.$nextTick(() => {
        let convertData = ['is_assign', 'is_platform_pay', 'is_send_back', 'is_cart', 'is_auth_price']
        list.forEach((item) => {
          convertData.forEach((i) => {
            if (i === 'is_assign' || i === 'is_send_back') {
              item[i] = item[i] == 1 ? '是' : '否'
            } else item[i] = item[i] == 1 ? '否' : '是'
          })
        })
        this.tableData = list
        this.tablePagination = page_info

      })
    },
    async getCountData() {
      this.allcount = await this.$api.applyCount({ type: 2 })
    },
    detailPage(id) {
      this.$router.push({
        name: 'enrollDetail',
        params: { id: id }
      })
    },
    moneyFormat(value) {
      return this.$utils.numberFormat(value, 2, '.', ',', 'round')
    }
  },
  mounted() {
    this.infoData()
    this.getCountData()
  }
}
</script>

<style scoped>

</style>
